<template>
  <div>
    <span class="nav-item">
      <b-link id="theme_selector" class="nav-link bv-no-focus-ring" tabindex="0" href="#"><PaletteIcon /></b-link>
    </span>

    <b-popover custom-class="theme_selector_popover" target="theme_selector" placement="bottomright" triggers="focus">
      <template v-slot:default>
        <div class="color" v-for="(c, index) in colors" :key="index" :style="{ 'background-color': c }" @click="themeChanged(c)">
          <span :class="{invisible: themeColor.color !== c}"><CheckIcon /></span>
        </div>
        <b-form-checkbox :checked="mode" name="check-button" @change="toggleMode" size="sm" switch>
          Mode: <strong>{{ mode ? "Dark" : "Light"}}</strong>
        </b-form-checkbox>
      </template>
    </b-popover>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import PaletteIcon from 'mdi-vue/Palette';
import CheckIcon from 'mdi-vue/CheckBold';
import { debounce } from '@/util/util';

export default {
  name: 'ThemeSelector',
  components: { PaletteIcon, CheckIcon },
  data() {
    return {};
  },
  computed: {
    ...mapState('theme', {
      colors: state => state.colors,
      themeColor: state => state.themeColor,
      mode: state => state.mode,
    }),
  },
  methods: {
    ...mapMutations('theme', ['setThemeMode', 'setThemeColor']),
    toggleMode() {
      this.setThemeMode(!this.mode);
      this.debouncedUpdateTheme();
    },
    themeChanged(color) {
      this.setThemeColor(color);
      this.debouncedUpdateTheme();
    },
    // debounce user input and execute this function only once after 5s timeout
    debouncedUpdateTheme: debounce(function updateTheme() {
      this.$store.dispatch('theme/updateTheme');
    }, 5000),
  },
  mounted() {
    this.$store.dispatch('theme/getColors');
  },
};
</script>

<style lang="stylus">
.theme_selector_popover
  .popover-body
    margin 0 auto
    max-width 13rem
  .color
    padding 0.3rem 0.8rem
    margin: 0 .2rem .1rem 0
    display inline-block
    cursor pointer

</style>
