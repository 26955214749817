<template>
  <div>
    <b-nav-item-dropdown right>
      <template v-slot:button-content>
        <TranslateIcon />
        <span class="d-none d-md-inline ml-1">{{$i18n.locale}}</span>
      </template>
      <template v-for="(lang) in AllLanguages">
        <b-dropdown-item @click="setLanguage(lang.locale)" :key="lang.locale" :active="lang.locale === $i18n.locale">
          {{ lang.localizedName }}
        </b-dropdown-item>
      </template>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import TranslateIcon from 'mdi-vue/Translate';
import { supportedLanguages, setLocale } from '@/i18n/lang';

export default {
  name: 'LanguageSelector',
  components: { TranslateIcon },
  data() {
    return {
      AllLanguages: supportedLanguages,
    };
  },

  methods: {
    setLanguage(locale) {
      setLocale(this.$i18n, locale);
    },
  },
};
</script>
