<template>
  <div class="navbar_wrapper container-fluid p-0 shadow" :class="{'isfixed': isFixed}">
    <b-navbar id="navbar_main" class="container-lg py-0" print>
      <b-navbar-brand class="mr-auto" :to="{name:'index'}">Wakaru.Org</b-navbar-brand>
      <b-navbar-nav>
        <LanguageSelector />
        <ThemeSelector />
      </b-navbar-nav>
      <NavBarLower class="d-none d-md-flex p-0" />
    </b-navbar>
    <NavBarLower id="navbar_lower" class="navbar_lower d-md-none py-0" :class="{'fixed-top': isFixed}" />
  </div>
</template>

<script>
import LanguageSelector from '@/components/ui/LanguageSelector.vue';
import ThemeSelector from '@/components/ui/ThemeSelector.vue';
import { debounce } from '@/util/util';
import NavBarLower from './NavBar_Lower.vue';

export default {
  name: 'SectionNavbar',
  components: {
    LanguageSelector, ThemeSelector, NavBarLower,
  },
  data() {
    return {
      mainNavbarHeight: 0,
      lowerNavbar: null,
      isFixed: false,
    };
  },

  mounted() {
    const mainNavbar = document.getElementById('navbar_main');
    if (mainNavbar) this.mainNavbarHeight = mainNavbar.offsetHeight;
    if (window) window.addEventListener('scroll', this.handleScroll, { passive: true });
  },
  beforeDestroy() {
    if (window) window.removeEventListener('scroll', this.handleScroll, { passive: true });
  },

  methods: {
    handleScroll: debounce(function handleScroll() {
      const isFixed = this.mainNavbarHeight && (window.pageYOffset >= this.mainNavbarHeight);
      if (!this.lowerNavbar) this.lowerNavbar = document.getElementById('navbar_lower');
      if (this.lowerNavbar && (this.isFixed !== isFixed)) {
        this.isFixed = isFixed;
        document.documentElement.style.setProperty('--navbar_lower_height', `${this.lowerNavbar.offsetHeight}px`);
      }
    }, 50), // handleScroll() called once in every 50ms
  },

};
</script>

<style lang="stylus">

:root
  --navbar_lower_height 0

.navbar_wrapper.isfixed
  margin-bottom var(--navbar_lower_height)

</style>
